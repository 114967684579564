<template>
<v-app>
    <div>
        <v-alert dark dismissible v-model="alert" border="left" class="alert" :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
            {{alertMessage}}
        </v-alert>
    </div>
    <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
            <div class="mb-8 mt-8 text-center">
                <h3>Add New Timesheet</h3>
                <hr/>
            </div>
            <v-form ref="salarycomponent" v-model="valid">
                <v-row no-gutters>
                    <v-col class="mb-6">
                        <!-- <TextField v-model="formData.personnel" :label="'Personnel'" /> -->
                        <v-btn class="btn-style justify-start text-style" @click.prevent="openPersonnelSearchModal" width="100%" height="38" outlined>
                            <v-icon left>mdi-magnify</v-icon> Personnel
                        </v-btn>
                    </v-col>

                    <v-col>
                        <SelectField :items="salaryCycles" v-model="formData.salaryCycle" :label="'Salary Cycle'" :itemText="'description'" :itemValue="'id'" :returnObject="true" @input="selectedCycle" />
                    </v-col>

                    <v-col cols="12" class="d-flex justify-end">
                        <Button :label="'Generate Timesheet'" :btnType="'Submit'" @onClick="generateTimesheet" :isLoading="creating" class="" />
                    </v-col>
                </v-row>
            </v-form>
            <!-- <p>{{personnel}}</p> -->
        </div>
    </div>

    <!-- Dialog box -->
    <Dialog ref="personnelSearch" :title="'Personnel Search'" :width="600">
        <v-row>
            <v-col cols="12">
                <v-text-field v-model="search" outlined label="Search" dense />
            </v-col>
            <v-col cols="12" class="employee-search">
                <v-data-table
                  dense
                  show-select
                  item-key="id"
                  :search="search"
                  disable-pagination
                  hide-default-footer
                  :loading="isLoading"
                  :items="employeeList"
                  :headers="searchHeaders"
                  v-model="selectedPersonnel"
                  class="elevation-1 row-pointer"
                  loading-text="Fetching personnel"
                >
                  <template v-slot:item.firstName="{ item }">
                    <span>
                      {{ item.firstName }}, {{ item.lastName }} {{ item.middleName}}
                    </span>
                  </template>
                  <template v-slot:item.departmentId="{ item }">
                    <span>
                      {{ item.department.name }}
                    </span>
                  </template>
                  <template v-slot:item.rotationId="{ item }">
                    <span>
                      {{ getRotationName(item.rotationId) }}
                    </span>
                  </template>
                </v-data-table>
            </v-col>
        </v-row>
        <template v-slot:footer>
            <Button :label="'Ok'" :btnType="'Cancel'" @onClick="closePersonnelSearchModal" />
        </template>
    </Dialog>
    <!--End Dialog box -->

    <!-- timesheet calendar  -->

    <div v-if="selectedPersonnel.length > 0" class="scroll row">
        <div class="col-md-10 col-sm-12 ml-auto mr-auto">
          <ValidationObserver ref="observer">
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Personnel</th>
                            <th>Rotation</th>
                            <th v-for="item in headers" :key="item.headerName">{{item.headerName}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="timesheet in timeSheets" :key="timesheet.employeeId">
                            <td>
                              <div class="actionBtn">
                                <v-icon class="delete-icon" small @click="removePersonnel(timesheet)">mdi-delete</v-icon>
                              </div>
                            </td>
                            <td>
                                {{timesheet.name}}
                            </td>
                            <td>
                                {{timesheet.rotation}}
                            </td>
                            <td
                              v-for="(item, index) in timesheet.timesheetItems"
                              :key="item.timesheetItemDate"
                              v-bind:class="timesheet.timesheetItems[index].type">
                                <span v-if="timesheet.timesheetItems[index].show === true">
                                  <!-- <b>Work Hrs</b> -->
                                  <!-- <ValidationProvider> -->
                                    <!-- <input title="Work Hours" type="Number" class="hour-input" v-model="timesheet.timesheetItems[index].hoursWorked" /> -->
                                    <vue-numeric-input :min="0" :max="timesheet.rotationHours" :controls="false" class="hour-input" title="Work Hours" v-model="timesheet.timesheetItems[index].hoursWorked" />
                                    <!-- <span>{{ errors }}</span> -->
                                  <!-- </ValidationProvider> -->
                                </span>
                                <span v-if="timesheet.timesheetItems[index].show === false">
                                  <b>{{timesheet.timesheetItems[index].type}}</b>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
          </ValidationObserver>
        </div>
    </div>
    <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isTimesheet" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/>
    <!--end timesheet calendar  -->
    <v-row v-if="selectedPersonnel.length > 0 ">
        <v-col cols="11 mt-5" class="d-flex justify-end">
            <Button :label="'Submit'" :btnType="'Submit'" @onClick="dialog = true" :disabled="!valid" :isLoading="isTimesheet" class="mr-4" />

            <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />
        </v-col>
    </v-row>
</v-app>
</template>

<script>
// import TextField from '@/components/ui/form/TextField.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import Button from '@/components/ui/form/Button.vue'
import Dialog from '@/components/ui/Dialog.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import { CREATE_TIMESHEET, SAVE_TIMESHEET } from '@/store/action-type'
import { salaryPeriodService, employeeService, rotationService } from '@/services'
import moment from 'moment'
import { ValidationObserver } from 'vee-validate'
import { ROTATION } from '@/utils/constants'

export default {
  components: {
    ConfirmationDialog,
    ValidationObserver,
    SelectField,
    Dialog,
    Button
  },
  props: ['value'],
  data () {
    return {
      rotations: [],
      employeeList: [],
      isLoading: false,
      dialog: false,
      selectedPersonnel: [],
      alertMessage: '',
      alert: false,
      alertType: '',
      valid: false,
      isTimesheet: false,
      formData: {
        requestId: '',
        personnel: '',
        salaryCycle: {}
      },
      search: '',
      personnelSearch: [],
      requestType: [],
      salaryCycles: [],
      personnel: [],
      selected: [],
      headers: [],
      timeSheets: [],
      searching: false,
      creating: false,
      searchHeaders: [
        {
          text: 'name',
          value: 'firstName'
        },
        {
          text: 'cai',
          value: 'cai'
        },
        {
          text: 'department',
          value: 'departmentId'
        },
        {
          text: 'rotation',
          value: 'rotationId'
        }
      ],
      personnelHeaders: [
        {
          glutenfree: true,
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    onSave () {
      this.isTimesheet = true
      this.$store.dispatch(SAVE_TIMESHEET, this.timeSheets).then(() => {
        this.showAlertMessage('Timesheet successfully saved', 'success')
        this.timeSheets = []
        this.personnelSearch = []
        this.personnel = []
        this.personnelHeaders = []
        this.isTimesheet = false
        this.dialog = false
      }).catch(() => {
        this.showAlertMessage('Unable to save Timesheet', 'error')
        this.isTimesheet = false
        this.dialog = false
      })
    },
    generateTimesheet () {
      const payload = []
      this.creating = true
      this.timeSheets.forEach(timesheet => {
        payload.push({
          employeeId: timesheet.employeeId,
          salaryPeriodId: this.formData.salaryCycle.id
        })
      })

      this.$store.dispatch(CREATE_TIMESHEET, payload).then((result) => {
        const newTimesheets = []
        const { data } = result
        this.timeSheets.forEach(timesheet => {
          const getTimesheet = data.find(item => item.employeeId === timesheet.employeeId)
          const newTimesheetItems = []
          const { rotation } = timesheet
          if (getTimesheet !== undefined) {
            this.headers.forEach(header => {
              const date = moment(header.date).format('MM-DD-YYYY')
              let timeSheetItem = getTimesheet.timesheetItems.find(item => moment(item.timesheetItemDate).format('MM-DD-YYYY') === date)
              if (timeSheetItem !== undefined) {
                // timeSheetItem.hoursWorked = timeSheetItem.hoursWorked === 0 ? getTimesheet.rotationHours : timeSheetItem.hoursWorked
                if (timeSheetItem.type === 'Holiday' || timeSheetItem.type === 'On Leave') {
                  timeSheetItem = Object.assign({}, timeSheetItem, { show: false })
                } else {
                  timeSheetItem = Object.assign({}, timeSheetItem, { show: true })
                }

                timeSheetItem.type = timeSheetItem.type === 'Holiday' ? 'holiday' : timeSheetItem.type
                newTimesheetItems.push(timeSheetItem)
              } else {
                newTimesheetItems.push({
                  timesheetId: getTimesheet.timesheetId,
                  timesheetItemDate: date,
                  hoursWorked: 0,
                  isRegular: false,
                  isOvertime: false,
                  isShift: false,
                  show: false,
                  type: rotation === ROTATION.OfficeRegular ? 'weekend' : 0
                })
              }
            })
            const newTimesheet = {
              timesheetId: getTimesheet.timesheetId,
              name: getTimesheet.name,
              employeeId: getTimesheet.employeeId,
              salaryPeriodId: this.formData.salaryCycle.id,
              rotationHours: getTimesheet.rotationHours,
              timesheetItems: newTimesheetItems,
              rotation: getTimesheet.rotation
            }

            newTimesheets.push(newTimesheet)
          }
        })

        this.timeSheets = newTimesheets
        this.showAlertMessage('Timesheet successfully created', 'success')
      }).catch(() => {
        this.showAlertMessage('Failed to create Timesheet', 'error')
      }).finally(() => {
        this.creating = false
      })
    },
    selectedCycle (salaryData) {
      let dateArr = []
      const prevDate = moment(salaryData.startDate).format('MM-DD-YYYY')
      const nextDate = moment(salaryData.endDate).format('MM-DD-YYYY')

      var start = new Date(prevDate)
      var end = new Date(nextDate)

      while (start <= end) {
        dateArr.push({
          headerName: moment(start).format('ddd, MMM D'),
          date: moment(start).format('MM-DD-YYYY')
        })
        var newDate = start.setDate(start.getDate() + 1)
        start = new Date(newDate)
      }

      this.headers = dateArr
      this.createTimesheetTable()
    },
    createTimesheetTable () {
      if (this.selectedPersonnel.length > 0) {
        this.selectedPersonnel.forEach(personnel => {
          const timesheetItems = []
          // this.headers.forEach(header => {
          //   timesheetItems.push({
          //     timesheetItemDate: header.date,
          //     hoursWorked: 0,
          //     isRegular: false,
          //     isOvertime: false,
          //     isShift: false,
          //     type: ''
          //   })
          // })

          const getTimesheet = this.timeSheets.find(timeSheet => timeSheet.employeeId === personnel.id)

          if (getTimesheet === undefined) {
            const timesheet = {
              employeeId: personnel.id,
              salaryPeriodId: this.formData.salaryCycle.id,
              rotation: this.getRotationName(personnel.rotationId),
              name: `${personnel.firstName}, ${personnel.lastName} ${personnel.middleName}`,
              timesheetItems
            }

            this.timeSheets.push(timesheet)
          }
        })
      }
    },
    openPersonnelSearchModal () {
      this.search = ''
      this.$refs.personnelSearch.openModal()
    },
    removePersonnel (item) {
      // let index = null
      const newTimesheet = this.timeSheets.filter(timeSheet => timeSheet.employeeId !== item.employeeId)
      const newPersonnel = this.personnelSearch.filter(person => person.id !== item.employeeId)
      this.timeSheets = newTimesheet
      this.personnel = newPersonnel

      // for (let i = 0; i < this.timeSheets.length; i++) {
      //   if (this.timeSheets[i].emloyeeId === item.emloyeeId) {
      //     index = i
      //     break
      //   }
      // }
      // this.timeSheets.splice(index, 1)
    },
    closePersonnelSearchModal () {
      this.personnelSearch = []
      this.$refs.personnelSearch.closeModal()
      if (this.selectedPersonnel.length > 0) {
        this.createTimesheetTable()
      }
    },
    onReset () {
      this.$refs.salarycomponent.reset()
    },
    personnelSearchFunction () {
      this.searching = true
      if (this.search === '') {
        this.personnelSearch = []
        return
      }

      employeeService.searchPersonnel(this.search).then(result => {
        this.personnelSearch = result.data
        this.searching = false
      })
    },
    getRotationName (id) {
      const rotation = this.rotations.find(item => item.id === id)
      if (rotation !== undefined) {
        return rotation.name
      }

      return ''
    }
  },
  mounted () {
    this.isLoading = true
    salaryPeriodService.getOpenSalaryPeriod().then(result => {
      this.salaryCycles = result.data
    })

    rotationService.getAllRotation().then(result => {
      this.rotations = result.data.items
    })

    employeeService.getAllEmployees().then(result => {
      this.employeeList = result.data.items
      this.isLoading = false
    }).catch(() => {
      this.isLoading = false
    })
  }
}
</script>

<style scoped>
  .employee-search {
    height: 300px;
    overflow-y: auto;
  }
  .holiday {
    background-color: orange;
    color: #fff;
  }

  .offday, .weekend {
    background-color: maroon;
    color: #fff;
  }

  .radiogroup {
      width: 50%;
      align-items: flex-start !important;
  }

  .row .col {
      box-shadow: none !important;
      padding: 0 !important;
  }

  .alert {
      position: fixed;
      z-index: 9999;
      top: 5px;
      min-width: 50%;
  }

  .alert-success {
      color: #155724;
      background-color: #d4edda;
      border-color: #c3e6cb;
  }

  .alert-error {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
  }

  .btn-style {
      color: rgba(0, 0, 0, 0.6);
      text-align: left;
  }

  .text-style {
      text-transform: capitalize;
  }

  .actionBtn {
      display: flex;
      justify-content: flex-end;
  }

  .delete-icon {
    color: red;
  }

  .hour-input {
      /* border: 1px rgb(156, 154, 154) solid; */
      width: 60px !important;
      /* padding: 5px 0px; */
      background: #fff;
  }

  .hour-input:active {
    background: #fff;
  }

  .v-data-table__wrapper {
      overflow-y: auto !important;
      max-height: 400px !important;
  }
</style>
